import { LawThemes, ModelType } from '@livv/models';
import { LawScope } from '@livv/models/lawScope';
import { Theme, createTheme } from '@mui/material/styles';
import { AllContentType, UIExtraContentType } from '@utils/types/search';

export const color = {
    accent: {
        brown: {
            1: '#8B6637',
            2: '#B08145',
            3: '#D1B28A',
            4: '#DFCAAE',
            5: '#EEE2D3',
            6: '#F5EEE5',
            default: '#C29965',
        },
        green: {
            1: '#33CBB0',
            2: '#66D8C4',
            3: '#7FDECD',
            4: '#99E5D7',
            5: '#B2EBE1',
            6: '#CCF2EB',
            default: '#00BE9C',
        },
        khaki: {
            1: '#465E53',
            2: '#5B7B6D',
            3: '#90ACA0',
            4: '#ADC2B9',
            5: '#CAD8D2',
            6: '#E8EEEB',
            default: '#729686',
        },
        lightBlue: {
            1: '#195A99',
            2: '#2174C5',
            3: '#3A8DDE',
            4: '#92C0ED',
            5: '#BDD9F4',
            6: '#E9F2FB',
            default: '#68A8E6',
        },
        orange: {
            1: '#C76C00',
            2: '#FF9414',
            3: '#FFAB47',
            4: '#FFC37A',
            5: '#FFDAAD',
            6: '#FFF1E0',
            default: '#DF7900',
        },
        pink: {
            1: '#862282',
            2: '#AF2DA9',
            3: '#CE40C8',
            4: '#E391DF',
            5: '#EDBAEB',
            6: '#F8E3F7',
            default: '#D969D4',
        },
        purple: {
            1: '#673ABB',
            2: '#926FD2',
            3: '#A182D8',
            4: '#BEA9E5',
            5: '#DCD0F1',
            6: '#EDE8F8',
            default: '#825ACC',
        },
        red: {
            1: '#A90D05',
            2: '#DA1106',
            3: '#FD574F',
            4: '#FB847E',
            5: '#FDB3B0',
            6: '#FEE3E1',
            default: '#FA554C',
        },
        royalBlue: {
            1: '#1C1ED3',
            2: '#6B6BEB',
            3: '#8A8BEF',
            4: '#AEAFF4',
            5: '#D2D2F9',
            6: '#E4E4FB',
            default: '#3E3FE5',
        },
        yellow: {
            1: '#CC920B',
            2: '#E4A40C',
            3: '#F7CA5F',
            4: '#F8D37C',
            5: '#FAE0A3',
            6: '#FCEFCF',
            default: '#F4B92D',
        },
    },
    black: '#000000',
    grey100: '#111827',
    grey200: '#374151',
    grey300: '#4B5563',
    grey400: '#9CA3AF',
    grey500: '#D2D9E2',
    grey600: '#E7EBF2',
    grey700: '#F7F9FC',
    grey800: '#FDFDFD',
    primaryDarken: '#6D5015',
    primaryDefault: '#93712C',
    primaryLighten: '#F4EEE1',
    stateError: '#D32F2F',
    stateHighlighted: '#FEF08A',
    stateInfo: '#0288D1',
    stateSuccess: '#4CAF50',
    stateWarning: '#ED6C02',
    white: '#FFFFFF',
};

const font = {
    primary: 'IBM Plex Sans, sans-serif',
    secondary: 'Playfair Display, serif',
};

export const accentColors = {
    0: color.accent.brown.default,
    1: color.accent.yellow.default,
    2: color.accent.green.default,
    3: color.accent.orange.default,
    4: color.accent.purple.default,
    5: color.accent.pink.default,
    6: color.accent.lightBlue.default,
    7: color.accent.royalBlue.default,
    8: color.accent.khaki.default,
    9: '#010043',
    // eslint-disable-next-line sort-keys
    10: '#323389',
    11: '#00BCD4',
    12: '#009688',
    13: '#CDDC39',
    14: '#FFEB3B',
    15: '#FF5722',
    16: '#D7CCC8',
    17: '#795548',
    18: '#9E9E9E',
    19: '#607D8B',
};

export const lawScopeColors: { [key in LawScope]: string } = {
    [LawScope.EU]: accentColors[10],
    [LawScope.FR]: accentColors[9],
};

export const modelTypeColor: {
    [key in AllContentType]: {
        color: string;
        backgroundColor: string;
    };
} = {
    [ModelType.ANALYSIS]: {
        backgroundColor: color.accent.purple[6],
        color: color.accent.purple.default,
    },
    [ModelType.ARTICLE]: {
        backgroundColor: color.accent.yellow[6],
        color: color.accent.yellow.default,
    },
    [ModelType.DECISION]: {
        backgroundColor: color.accent.green[6],
        color: color.accent.green.default,
    },
    [ModelType.GLOSSARY_TERM]: {
        backgroundColor: color.accent.orange[6],
        color: color.accent.orange.default,
    },
    [ModelType.LAW_TEXT]: {
        backgroundColor: color.accent.pink[6],
        color: color.accent.pink.default,
    },
    [ModelType.PUBLICATION_SHEET]: {
        backgroundColor: color.accent.lightBlue[6],
        color: color.accent.lightBlue.default,
    },
    [ModelType.STATISTIC]: {
        backgroundColor: color.accent.khaki[6],
        color: color.accent.khaki.default,
    },
    [UIExtraContentType.BIBLIOGRAPHY]: {
        backgroundColor: color.accent.royalBlue[6],
        color: color.accent.royalBlue.default,
    },
};

export const treeThemeColor = {
    [LawThemes.ECONOMIC_LAW]: accentColors[1],
    [LawThemes.COMMERCIAL_LAW]: accentColors[2],
    [LawThemes.PROCEDURAL_LAW]: accentColors[3],
    [LawThemes.PRACTICAL_WORKS]: accentColors[0],
    [LawThemes.OTHER]: accentColors[4],
};

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        largeDesktop: true;
        lg: true;
        navBar: true;
        md: true;
        sm: true;
        tablet: true;
        xl: true;
        xs: true;
    }
}

// Create a theme instance.
const customTheme: Readonly<Theme> = createTheme({
    breakpoints: {
        values: {
            largeDesktop: 1600,
            lg: 1280,
            md: 960,
            navBar: 1370,
            sm: 600,
            tablet: 960,
            xl: 1920,
            xs: 0,
        },
    },
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    backgroundColor: 'inherit',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                popper: {
                    paddingTop: 8,
                },
                root: {
                    '& .MuiOutlinedInput-root': {
                        padding: 2,
                    },
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                colorDefault: {
                    backgroundColor: color.grey300,
                },
            },
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    color: color.grey300,
                },
                separator: {
                    margin: 0,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    '&.Mui-disabled': {
                        backgroundColor: color.grey600,
                        color: color.grey300,
                    },
                    backgroundColor: color.primaryDefault,
                    boxShadow: 'none',
                    color: color.grey800,
                },
                outlined: {
                    '&:hover': {
                        borderWidth: 2,
                    },
                    borderColor: color.primaryDefault,
                    borderWidth: 2,
                    color: color.primaryDefault,
                },
                root: {
                    borderRadius: 3,
                    fontSize: 16,
                    fontWeight: 600,
                },
            },
            variants: [
                {
                    props: { variant: 'ghost' },
                    style: {
                        '&:hover': {
                            backgroundColor: color.primaryLighten,
                        },
                        backgroundColor: color.grey700,
                        color: color.primaryDefault,
                    },
                },
                {
                    props: { color: 'secondary', variant: 'ghost' },
                    style: {
                        color: color.grey300,
                    },
                },
            ],
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: color.grey800,
                    border: `2px solid ${color.grey600}`,
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: color.grey400,
                    fontWeight: 400,
                    marginLeft: 0,
                    marginRight: 8,
                    padding: 0,
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                filled: {
                    backgroundColor: color.grey600,
                    color: color.grey100,
                },
                label: {
                    padding: '4px 8px',
                },
                root: {
                    borderRadius: 4,
                    fontSize: 14,
                    fontWeight: 600,
                    height: 'auto',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                    width: '6px',
                },
                '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                    background: `${color.grey400}`,
                    borderRadius: '5px',
                },
                '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
                    background: 'rgba(2, 4, 30, 0.1)',
                    borderRadius: '5px',
                },
                overflow: 'overlay',
                scrollbarColor: `${color.grey400} rgba(2, 4, 30, 0.1)`,
                scrollbarWidth: 'thin',
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                    marginRight: 0,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    marginBottom: 8,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '& input': {
                        color: color.grey100,
                        fontSize: 16,
                        padding: '9px 0',
                    },
                    '& input::placeholder': {
                        color: color.grey400,
                        opacity: 1,
                    },
                    '&.Mui-focused': {
                        borderColor: color.grey400,
                    },
                    border: `1px solid ${color.grey600}`,
                    borderRadius: 4,
                    padding: '0 12px',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-focused': {
                        color: color.grey300,
                    },
                    color: color.grey300,
                    fontSize: 16,
                    marginBottom: 6,
                    marginTop: 8,
                    position: 'relative',
                    transform: 'none',
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    borderRadius: 6,
                    height: 6,
                    width: '100%',
                },
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    backgroundColor: 'inherit',
                    color: color.grey400,
                    fontSize: '1rem',
                    fontWeight: 700,
                    lineHeight: '1.5rem',
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    backgroundColor: color.grey100,
                    color: color.grey800,
                },
                paper: {
                    borderColor: color.grey400,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    marginTop: 7,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&:hover, &.Mui-focusVisible': {
                        backgroundColor: color.grey300,
                    },
                    borderRadius: 4,
                    marginLeft: 8,
                    marginRight: 8,
                },
            },
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    '& .Mui-disabled': {
                        visibility: 'hidden',
                    },
                    '& button': {
                        '&.Mui-selected': {
                            '&:hover': {
                                backgroundColor: color.grey600,
                            },
                            backgroundColor: color.grey600,
                            fontWeight: 400,
                        },
                        '&.MuiPaginationItem-previousNext': {
                            color: color.grey400,
                        },
                        border: `1px solid ${color.grey600}`,
                        borderRadius: '2px',
                        color: color.grey300,
                    },
                    '& button:hover': {
                        backgroundColor: 'inherit',
                        fontWeight: 600,
                    },

                    borderRadius: 4,
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: color.grey400,
                    marginLeft: 0,
                    marginRight: 8,
                    padding: 0,
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: color.primaryDefault,
                },
                root: {
                    '& button': {
                        borderRadius: '8px 8px 0 0',
                        color: color.grey400,
                        fontSize: 16,
                        fontWeight: 400,
                    },
                    '& button.Mui-selected': {
                        color: color.primaryDefault,
                        fontWeight: 600,
                    },
                    borderBottom: `1px solid ${color.grey600}`,
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        backgroundColor: 'rgba(210, 217, 226, 0.1)',
                        color: color.grey400,
                    },
                    '&.Mui-selected, &.Mui-selected:hover': {
                        backgroundColor: color.grey400,
                        color: color.white,
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(210, 217, 226, 0.3)',
                    },
                    borderColor: color.grey500,
                    color: color.grey300,
                    fontWeight: 400,
                    lineHeight: 1.3,
                    padding: 8,
                },
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    backgroundColor: color.grey800,
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                arrow: {
                    color: color.grey100,
                },
                tooltip: {
                    backgroundColor: color.grey100,
                    color: color.grey800,
                    padding: '3px',
                    pointerEvents: 'auto',
                },
            },
        },
    },
    palette: {
        action: {
            selected: color.stateHighlighted,
        },
        background: {
            default: color.grey800,
            paper: color.grey700,
        },
        common: {
            black: color.black,
            white: color.white,
        },
        error: {
            main: color.stateError,
        },
        grey: {
            100: color.grey100,
            200: color.grey200,
            300: color.grey300,
            400: color.grey400,
            500: color.grey500,
            600: color.grey600,
            700: color.grey700,
            800: color.grey800,
            900: color.white,
        },
        info: {
            main: color.stateInfo,
        },
        mode: 'light',
        primary: {
            dark: color.primaryDarken,
            light: color.primaryLighten,
            main: color.primaryDefault,
        },
        secondary: {
            contrastText: color.grey800,
            main: color.grey100,
        },
        success: {
            contrastText: color.grey800,
            main: color.stateSuccess,
        },
        text: {
            disabled: color.grey100,
            primary: color.grey300,
            secondary: color.grey100,
        },
        warning: {
            main: color.stateWarning,
        },
    },
    shape: {
        borderRadius: 8,
    },
    typography: {
        body1: {
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 1.5,
        },
        body2: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 1.5,
        },
        button: {
            textTransform: 'none',
        },
        fontFamily: Object.values(font).join(','),
        fontWeightBold: 700,
        fontWeightMedium: 600,
        fontWeightRegular: 400,
        h1: {
            color: color.grey100,
            fontFamily: font.secondary,
            fontSize: 32,
            fontWeight: 400,
            lineHeight: 1.2,
        },
        h2: {
            color: color.grey100,
            fontSize: 24,
            fontWeight: 600,
            lineHeight: 1.3,
        },
        h3: {
            color: color.grey100,
            fontSize: 20,
            fontWeight: 600,
            lineHeight: 1.4,
        },
        h4: {
            color: color.grey100,
            fontSize: 18,
            fontWeight: 700,
            lineHeight: 1.5,
        },
        h5: {
            color: color.grey100,
            fontSize: 16,
            fontWeight: 700,
        },
    },
});

const theme: Readonly<Theme> = createTheme(customTheme, {
    typography: {
        h1: {
            [customTheme.breakpoints.down('md')]: {
                fontSize: 24,
            },
        },
        h2: {
            [customTheme.breakpoints.down('md')]: {
                fontSize: 18,
            },
        },
    },
});

theme.shadows[1] = '0 2px 10px 0 rgba(0, 0, 0, 0.1)';
theme.shadows[2] = '0 4px 10px 0 rgba(0, 0, 0, 0.1)';
theme.shadows[3] = '0 4px 20px 0 rgba(0, 0, 0, 0.1)';

export default theme;
